<template>
    <div>
        <!-- Page Title Section -->
        <section class="page-title">
            <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern5+')'}"></div>
            <div class="pattern-layer-two" :style="{backgroundImage: 'url('+bgPattern6+')'}"></div>
            <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-three" :style="{backgroundImage: 'url('+icon6+')'}"></div>
            <div class="icon-layer-four" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>
            <div class="icon-layer-five" :style="{backgroundImage: 'url('+iconPattern2+')'}"></div>
            <div class="auto-container">
                <h1>Ethereum Packages</h1>
                <ul class="page-breadcrumb">
                    <li>
                        <router-link :to="{name: 'Home'}">home</router-link>
                    </li>
                    <li>Ethereum</li>
                </ul>
            </div>
        </section>
        <!-- End Page Title Section -->

        <!-- Price Page Section -->
        <section class="price-page-section" style="margin-bottom: 150px">
            <div class="auto-container">
                <div class="row clearfix">

                    <!-- Price Block -->
                    <div class="price-block col-lg-4 col-md-6 col-sm-12" v-for="(val, plan, idx) in packages" :key="idx">
                        <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div class="icon-box">
                                <span class="icon flaticon-bar-chart"></span>
                            </div>
                            <div class="price-box">
                                <div class="title">{{ plan }}</div>
                                <div class="price">${{val.minDeposit.toLocaleString()}}</div>
                            </div>
                            <h6>${{val.minDeposit.toLocaleString()}} - ${{val.maxDeposit.toLocaleString()}}</h6>
                            <ul class="price-list">
                                <li>Daily Profit: <span class="txt-primary">{{ val.dailyProfit }}%</span> </li>
                                <li>Contract Life: <span class="txt-primary">{{val.lifeSpan}} Months</span></li>
                                <li>Referral Bonus: <span class="txt-primary">{{val.referralBonus}}%</span></li>
                                <li>Business Days: <span class="txt-primary">Mon-Friday</span></li>
                                <li>Withdrawal: <span class="txt-primary">{{val.withdrawal}}</span></li>
                            </ul>
                            <div class="lower-box">
                                <a href="/register" class="arrow">
                                    <span class="txt">Register</span><span class="arrow-icon flaticon-right-arrow-1"></span>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- End Price Page Section -->

    </div>
</template>

<script>
import icon1 from "../../../assets/images/icons/icon-1.png";
import icon2 from "../../../assets/images/icons/icon-2.png";
import icon3 from "../../../assets/images/icons/icon-3.png";
import icon4 from "../../../assets/images/icons/icon-4.png";
import bgPattern5 from "../../../assets/images/background/pattern-5.png";
import bgPattern6 from "../../../assets/images/background/pattern-6.png";
import icon5 from "../../../assets/images/icons/icon-5.png";
import icon6 from "../../../assets/images/icons/icon-6.png";
import iconPattern1 from "../../../assets/images/icons/pattern-1.png";
import iconPattern2 from "../../../assets/images/icons/pattern-2.png";
import Plans from "../../../utils/Plans";

export default {
    name: "Ethereum",
    data(){
        return {
            icon1, icon2, icon3, icon4,
            bgPattern5, bgPattern6,
            icon5, icon6, iconPattern1,
            iconPattern2,
            packages: Plans.all.ETHEREUM
        }
    }
}
</script>

<style scoped>
.txt-primary{
    color: #ff7e00
}
</style>